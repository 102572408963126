import React from 'react'
// import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'

// import 'styles/PostTemplate.scss'

// export const pageQuery = graphql`
//   query Post($wordpress_id: Int) {
//     wordpressPage(wordpress_id: { eq: $wordpress_id }) {
//       title
//     }
//   }
// `

const PostTemplate = () => {
  return (
    <Layout>
      <SEO title="template" />
      <div className="post-template">
        
      </div>
    </Layout>
  )
}

export default PostTemplate